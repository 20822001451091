<template>
  <div>
    <div class="pb-3">
      <h3>{{ $t("itemsPage.quantity") }}</h3>
      <v-switch v-model="filters.isAvailable" @change="updateFilters" :label="$t('itemsPage.onlyAvailable')"></v-switch>
      <v-divider style="border: 0.4px black solid"></v-divider>
    </div>
    <div class="pb-3">
      <div class="pb-3">
        <h3>{{ $t("itemsPage.category") }}</h3>
        <div class="ml-n4" v-if="loadingCategories">
          <v-skeleton-loader class="mb-n4" width="100%" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader class="mb-n4" width="100%" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader class="mb-n4" width="100%" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader width="100%" type="list-item"></v-skeleton-loader>
        </div>
        <v-treeview selectable :items="categories" v-model="filters.categoryIds" @input="updateFilters" activatable
          selection-type="fix your god damn documentation" item-key="id">
          <template v-slot:label="{ item }">
            <div ref="treeviewItem">
              {{ $i18n.locale == "ar" ? item.arName : item.name }}
            </div>
          </template>
        </v-treeview>
      </div>
      <v-divider style="border: 0.4px black solid"></v-divider>
    </div>
    <!-- <div class="pb-3" v-if="!loadingDimensions && dimensions.length">
      <div class="pb-3">
        <h3>{{ $t("itemsPage.dimension") }}</h3>
        <div class="ml-n4" v-if="loadingDimensions">
          <v-skeleton-loader class="mb-n4" width="100%" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader class="mb-n4" width="100%" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader class="mb-n4" width="100%" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader width="100%" type="list-item"></v-skeleton-loader>
        </div>
        <v-treeview selectable :items="dimensions" v-model="filters.dimension" item-key="name" item-text="name"
          @input="updateFilters" selection-type="fix your god damn documentation" item>
          <template v-slot:label="{ item }">
            <div style="direction: ltr;" :class="`text-${$i18n.locale == 'ar' ? 'end' : 'start'}`">{{ item.name }}</div>
          </template>
        </v-treeview>
      </div>
      <v-divider style="border: 0.4px black solid"></v-divider>
    </div> -->
    <div class="pb-3" v-if="!loadingTextures && textures.length">
      <div class="pb-3">
        <h3>{{ $t("itemsPage.texture") }}</h3>
        <div class="ml-n4" v-if="loadingTextures">
          <v-skeleton-loader class="mb-n4" width="100%" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader class="mb-n4" width="100%" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader class="mb-n4" width="100%" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader width="100%" type="list-item"></v-skeleton-loader>
        </div>
        <v-treeview selectable :items="textures" v-model="filters.texture" @input="updateFilters"
          selection-type="fix your god damn documentation" item-key="name" item-text="name">
        </v-treeview>
      </div>
      <v-divider style="border: 0.4px black solid"></v-divider>
    </div>
    <div class="pb-3" v-if="!loadingFinsishings && finishings.length">
      <div class="pb-3">
        <h3>{{ $t("itemsPage.finishing") }}</h3>
        <div class="ml-n4" v-if="loadingFinsishings">
          <v-skeleton-loader class="mb-n4" width="100%" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader class="mb-n4" width="100%" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader class="mb-n4" width="100%" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader width="100%" type="list-item"></v-skeleton-loader>
        </div>
        <v-treeview selectable :items="finishings" v-model="filters.finishing" @input="updateFilters"
          selection-type="fix your god damn documentation" item-key="name" item-text="name">
        </v-treeview>
      </div>
      <v-divider style="border: 0.4px black solid"></v-divider>
    </div>
    <div class="pb-3">
      <div class="pb-8">
        <h3>{{ $t("itemsPage.price") }}</h3>

        <v-row class="justify-center pt-2">
          <v-col cols="4">
            <v-text-field :value="filters.range[0]" hide-details type="number" dense :label="$t('itemsPage.min')" outlined
              @change="$set(filters.range, 0, $event)"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field :value="filters.range[1]" hide-details dense :label="$t('itemsPage.max')" outlined type="number"
              @change="$set(filters.range, 1, $event)"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn color="black" dark elevation="0" :ripple="false" @click="handlePriceRange"><v-icon>{{
              $t("itemsPage.priceArrowIcon") }}</v-icon></v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "SideBar",
  data() {
    return {
      fromPrice: 0,
      toPrice: 1000,

      filters: {
        range: [0, 1000],
        isAvailable: "",
        texture: [],
        finishing: [],
        dimension: [],
        categoryIds: [],
      },
      loadingTextures: false,
      loadingCategories: false,
      loadingDimensions: false,
      loadingFinsishings: false,

      filterDisabled: true,
    };
  },
  computed: {
    ...mapState({
      textures: (state) => {
        return state.itemsModule.textures.map((e, i) => {
          let obj = { name: e, id: i };
          return obj
        })
      },
      // dimensions: (state) => {
      //   return state.itemsModule.dimensions.map((e, i) => {
      //     let obj = { name: e, id: i };
      //     return obj
      //   })
      // },
      finishings: (state) => {
        return state.itemsModule.finishings.map((e, i) => {
          let obj = { name: e, id: i };
          return obj
        })
      },
      categories: (state) => state.itemsModule.categories,
      oldFilters: (state) => state.itemsModule.filters,
    }),
  },
  created() {
    this.getTextures();
    this.getDimensions();
    this.getFinishings();
    this.getCategories();

    this.filters = this.oldFilters;
  },
  methods: {
    updateFilters: _.debounce(function () {
      this.$store.commit("itemsModule/SET_FILTERS", {
        ...this.oldFilters,
        ...this.filters,
      });
      this.$emit("update");
    }, 750),
    getTextures() {
      this.loadingTextures = true;

      this.$store
        .dispatch("itemsModule/getTextures")
        .then(() => { })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.loadingTextures = false;
        });
    },
    getCategories() {
      this.loadingCategories = true;

      this.$store
        .dispatch("itemsModule/getCategories")
        .then(() => { })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.loadingCategories = false;
        });
    },
    getDimensions() {
      this.loadingDimensions = true;

      this.$store
        .dispatch("itemsModule/getDimensions")
        .then(() => { })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.loadingDimensions = false;
        });
    },
    getFinishings() {
      this.loadingFinsishings = true;

      this.$store
        .dispatch("itemsModule/getFinishings")
        .then(() => { })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.loadingFinsishings = false;
        });
    },
    handlePriceRange() {
      this.filters.fromPrice = this.filters.range[0];
      this.filters.toPrice = this.filters.range[1];
      this.updateFilters();
    },
  },
};
</script>
